<template>
  <div>
    <div class="hint-row" v-for="(hint, index) in redemptionHints" :key="index">
      <div class="hint" v-if="hint.display">
        <v-icon :size="$vuetify.breakpoint.smAndUp ? 26 : 22" color="#333333">
          mdi-{{ hint.icon }}
        </v-icon>

        <div>
          <span> {{ $t(`redemptions.hints.${hint.key}`) }} </span>
          <span
            @click="openHintsDialog(hint.key)"
            class="info-icon"
            v-if="hint.hasInfoModal"
          >
            ⓘ
          </span>
        </div>
      </div>
    </div>

    <ReusableDialog
      v-model="displayDialog"
      :text="dialogText"
      @display="displayDialog = $event"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ReusableDialog from "@/components/shared/ReusableDialog.vue";

export default {
  name: "RedemptionHints",

  data: () => ({
    displayDialog: false,
    dialogText: "",
  }),

  components: { ReusableDialog },

  computed: {
    ...mapGetters(["redemption"]),

    redemptionHints() {
      return [
        {
          key: "variableAmount",
          icon: "alert-circle-outline",
          display: true,
          hasInfoModal: true,
        },
        {
          key: "proporcionalDistribution",
          icon: "percent-outline",
          display: this.redemption.redemptionOptions.fromTotal,
          hasInfoModal: true,
        },
        {
          key: this.paymentDateKey,
          icon: "calendar-blank-outline",
          display: true,
        },
      ];
    },

    paymentDateKey() {
      if (this.liquidezRedemption) return "liquidezPaymentDate";
      if (this.equilibrioRedemption) return "equilibrioPaymentDate";

      return "normalPaymentDate";
    },

    liquidezRedemption() {
      if (!Object.hasOwn(this.redemption, "redemptionList")) {
        return false;
      }
      return (
        this.redemption.redemptionList.every(
          redemption => Number(redemption.fundRun) === 9994,
        ) && !this.redemption.fromAllFunds
      );
    },

    equilibrioRedemption() {
      if (!Object.hasOwn(this.redemption, "redemptionList")) {
        return false;
      }

      return (
        this.redemption.redemptionList.every(
          redemption => Number(redemption.fundRun) === 10275,
        ) && !this.redemption.fromAllFunds
      );
    },
  },

  methods: {
    openHintsDialog(hintsCategory) {
      this.displayDialog = true;
      this.dialogText = this.$t(`redemptions.dialogHints.${hintsCategory}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.hint {
  display: flex;
  align-items: flex-start;
  gap: 0 20px;

  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #333333;

  @media (max-width: 600px) {
    gap: 0 10px;
    font-size: 12px;
  }
}
.hint-row + .hint-row {
  margin-top: 20px;
}

.info-icon {
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
}
</style>
