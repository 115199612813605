/* eslint-disable import/no-extraneous-dependencies, arrow-parens */
import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import defaultState from "./default-state.js";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,

  reducer: (state) => ({
    signupStep: state.signupStep,
    signup: state.signup,
    targetFund: state.targetFund,
    redemptionData: state.redemptionData,
    reinvestmentData: state.reinvestmentData,
    investData: state.investData,
    customerDividendsData: state.customerDividendsData,
    dividendDeclarationAnswer: state.dividendDeclarationAnswer,
    investTime: state.investTime,
    routeStack: state.routeStack,
  }),
});

function getDefaultState() {
  return {
    authenticatedStatus: "",
    loading: false,
    loadingBalanceHistory: false,
    loadingProfile: false,
    loadingPendingMovements: false,
    loadingReinvestments: false,
    loadingFundsMovements: false,
    fundsData: null,
    fundsMovementsData: null,
    balanceHistory: null,
    fundsDetailedData: null,
    profileData: {
      id: null,
      gender: null,
      identifier: null,
      documentNumber: null,
      name: null,
      lastName: null,
      profiling: null,
      birthdate: null,
      entityType: null,
      maritalStatus: null,
      conjugalRegime: null,
      capitalOrigin: null,
      capitalOriginCountry: null,
      reinvestDistributions: null,
      selfEmployed: null,
      nationality: null,
      birthCountry: null,
      occupation: null,
      profession: null,
      isPep: null,
      isCrs: null,
      isFatca: null,
      taxIdentificationNumbers: [{
        id: null,
        country: null,
        identification: null,
      }],
      netWorth: null,
      salaryRange: null,
      documentExpirationDate: null,
      spouses: [
        {
          id: null,
          identifier: null,
          name: null,
          lastName: null,
        },
      ],
      emails: [{ email: null, principal: false }],
      phones: [{ phone: null }],
      addresses: [
        {
          country: null,
          houseNumber: null,
          interiorNumber: null,
          houseType: null,
          locality: null,
          region: null,
          streetName: null,
        },
      ],
      bankAccounts: [
        {
          id: null,
          accountNumber: null,
          currency: null,
          updatedAt: null,
          bank: {
            id: null,
            name: null,
          },
          bankAccountType: {
            id: null,
            typeName: null,
          },
        },
      ],
    },
    totalResults: null,
    currentBalance: null,
    currency: null,
    otherFunds: [],
    funds: [],
    fundsResults: [],
    balanceDetails: null,
    pendingMovementsData: [],
    reinvestmentsData: [],
    fintocStatus: null,
    user: {},
    mutationErrors: [],

    // Investment status
    investTime: null,

    // Signup
    signup: {
      email: null,
      // Status
      status: {
        completedValidation: false,
        completedIdentification: false,
        completedProfiling: false,
        completedContract: false,
        completedContractCompass: false,
        compassContractDate: null,
        contractDate: null,
        pendingProfilingIdentificationReview: false,
        acceptedProfilingIdentificationReview: false,
      },
      // Credentials
      proposedEmail: null,
      pwd: null,
      // Validation
      rut: null,
      documentNumber: null,
      front: null,
      back: null,
      // Identificaton
      name: null,
      fatherFamilyName: null,
      motherFamilyName: null,
      familyName: null,
      gender: null,
      birthdate: null,
      birthCountry: null,
      nationality: null,
      phone: null,
      country: null,
      region: null,
      locality: null,
      street: null,
      houseNumber: null,
      interiorNumber: null,
      maritalStatus: null,
      spouseName: null,
      spouseFamilyName: null,
      spouseIdentifier: null,
      conjugalRegime: null,
      // Profiling
      profiling: {
        manual: null,
        profilingAnswers: [],
      },
      referredFrom: null,
      referralSource: null,
      occupation: null,
      profession: null,
      selfEmployed: null,
      tinValues: null,
      capitalOrigin: null,
      netWorth: null,
      salaryRange: null,
      isPep: false,
      isCrs: false,
      isFatca: false,
      capitalOriginCountry: null,
      taxIdentificationNumbers: [{
        country: null,
        identification: null,
      }],
      // Contract
      reinvestDividends: true,
    },
    targetFund: null,
    utmSource: null,
    utmContent: null,
    utmCampaign: null,

    // Redemptions
    redemptionData: {},
    reinvestmentData: {},

    // Invest
    investData: {},

    // Dividends
    loadingCustomerDividends: false,
    customerDividendsData: null,
    dividendDeclarationAnswer: null,

    // Routes
    routeStack: [],
  };
}

export default new Vuex.Store({
  state: getDefaultState(),

  mutations: {
    setAuthenticatedStatus(state, payload) {
      state.authenticatedStatus = payload;
    },

    setFundsData(state, payload) {
      state.fundsData = payload;
    },

    setFundsMovementsData(state, payload) {
      state.fundsMovementsData = payload;
    },

    setFundsDetailedData(state, payload) {
      state.fundsDetailedData = payload;
    },

    setBalanceHistoryData(state, payload) {
      state.balanceHistory = payload;
    },

    setBalanceDetails(state, payload) {
      state.balanceDetails = payload;
    },

    setProfileData(state, payload) {
      state.profileData = payload;
    },

    setTotalResults(state, payload) {
      state.totalResults = payload;
    },

    setLoading(state, payload) {
      state.loading = payload;
    },

    setLoadingBalanceHistory(state, payload) {
      state.loadingBalanceHistory = payload;
    },

    setLoadingProfile(state, payload) {
      state.loadingProfile = payload;
    },

    setLoadingPendingMovements(state, payload) {
      state.loadingPendingMovements = payload;
    },

    setLoadingFundsMovements(state, payload) {
      state.loadingFundsMovements = payload;
    },

    setLoadingReinvestments(state, payload) {
      state.loadingReinvestments = payload;
    },
    setCurrency(state, payload) {
      state.currency = payload;
    },

    // Signup mutations
    resetSignup(state) {
      Object.keys(state.signup).forEach((key) => {
        state.signup[key] = null;
      });
    },
    setEmail(state, payload) {
      state.signup.email = payload;
    },

    setProposedEmail(state, payload) {
      state.signup.proposedEmail = payload;
    },

    setStep(state, payload) {
      state.signupStep = payload.step;
    },

    setPersonal(state, payload) {
      state.signup.name = payload.name;
      state.signup.fatherFamilyName = payload.fatherFamilyName;
      state.signup.motherFamilyName = payload.motherFamilyName;
      state.signup.familyName = payload.familyName;
      state.signup.gender = payload.gender;
      state.signup.birthdate = payload.birthdate;
      state.signup.birthCountry = payload.birthCountry;
      state.signup.nationality = payload.nationality;
      state.signup.maritalStatus = payload.maritalStatus;
    },

    setSpouse(state, payload) {
      state.signup.spouseName = payload.spouseName;
      state.signup.spouseFamilyName = payload.spouseFamilyName;
      state.signup.spouseIdentifier = payload.spouseIdentifier;
    },

    setConjugalRegime(state, payload) {
      state.signup.conjugalRegime = payload;
    },

    setContactData(state, payload) {
      state.signup.phone = payload.phone;
      state.signup.country = payload.country;
      state.signup.region = payload.region;
      state.signup.locality = payload.locality;
      state.signup.street = payload.street;
      state.signup.houseNumber = payload.houseNumber;
      state.signup.interiorNumber = payload.interiorNumber;
    },

    setSocialValues(state, payload) {
      state.signup.email = payload.email;
      state.signup.name = payload.given_name;
      state.signup.familyName = payload.familyName;
    },

    setUserData(state, payload) {
      state.user = payload;
    },

    setIdentifier(state, payload) {
      state.signup.rut = payload.customerIdentifier;
      state.signup.documentNumber = payload.documentNumber;
    },

    setIdentificationDocumentImages(state, payload) {
      state.signup.front = payload.front;
      state.signup.back = payload.back;
    },

    setContact(state, payload) {
      state.signup.phone = payload.phone;
      state.signup.street = payload.street;
      state.signup.region = payload.region;
      state.signup.locality = payload.locality;
      state.signup.houseNumber = payload.houseNumber;
      state.signup.country = payload.country;
      state.signup.houseType = payload.houseType;
      state.signup.interiorNumber = payload.interiorNumber;
    },

    // eslint-disable-next-line no-unused-vars
    cleanStore(state, payload) {
      Object.assign(state, getDefaultState());
    },

    setCustomerChoices(state, payload) {
      state.customerChoices = payload;
    },

    setPendingMovementsData(state, payload) {
      state.pendingMovementsData = payload;
    },

    setReinvestmentsData(state, payload) {
      state.reinvestmentsData = payload;
    },

    setFintocStatus(state, payload) {
      state.fintocStatus = payload;
    },

    setPwd(state, payload) {
      state.signup.pwd = payload.pwd;
    },

    setReferralSource(state, payload) {
      state.signup.referralSource = payload;
    },

    setTargetFund(state, payload) {
      state.targetFund = payload;
    },

    setUtmParams(state, payload) {
      state.utmSource = payload?.utmSourceParam;
      state.utmContent = payload.utmContentParam;
      state.utmCampaign = payload?.utmCampaignParam;
    },

    setRedemptionData(state, payload) {
      state.redemptionData = payload;
    },

    setReinvestmentData(state, payload) {
      state.reinvestmentData = payload;
    },

    setSignupStatus(state, payload) {
      state.signup.rut = payload.rut !== undefined ? payload.rut : state.signup.rut;
      state.signup.status.completedValidation = payload.completedValidation !== undefined ? payload.completedValidation :
        state.signup.status.completedValidation;
      state.signup.status.completedIdentification = payload.completedIdentification !== undefined ? payload.completedIdentification :
        state.signup.status.completedIdentification;
      state.signup.status.completedProfiling = payload.completedProfiling !== undefined ? payload.completedProfiling :
        state.signup.status.completedProfiling;
      state.signup.status.completedContract = payload.completedContract !== undefined ? payload.completedContract :
        state.signup.status.completedContract;
      state.signup.status.completedContractCompass = payload.completedContractCompass !== undefined ? payload.completedContractCompass :
        state.signup.status.completedContractCompass;
      state.signup.status.compassContractDate = payload.compassContractDate !== undefined ?
        payload.compassContractDate : state.signup.status.compassContractDate;
      state.signup.status.contractDate = payload.contractDate !== undefined ?
        payload.contractDate : state.signup.status.contractDate;
      state.signup.status.pendingProfilingIdentificationReview = payload.pendingProfilingIdentificationReview !== undefined ?
        payload.pendingProfilingIdentificationReview : state.signup.status.pendingProfilingIdentificationReview;
      state.signup.status.acceptedProfilingIdentificationReview = payload.acceptedProfilingIdentificationReview !== undefined ?
        payload.acceptedProfilingIdentificationReview : state.signup.status.acceptedProfilingIdentificationReview;
    },

    saveMutationError(state, payload) {
      state.mutationErrors.push({
        code: payload.code, // like 400, 401, 500
        name: payload.name, // the mutation name, for example "sendProfilingData"
        view: payload.view, // like contact, account, accountStatements, etc
      });
    },

    //  profiling flow
    setProfilingCustomerAnswer(state, payload) {
      state.signup.profiling.profilingAnswers
        .splice(payload.questionNumber - 1, 1, payload.questionAnswer);
    },

    setManualProfilingCustomerAnswer(state, payload) {
      state.signup.profiling.manual = payload;
    },

    setProfilingCustomerReferralAnswer(state, payload) {
      state.signup.referredFrom = payload;
    },

    setProfilingCustomerLaborData(state, payload) {
      state.signup.occupation = payload.occupation;
      state.signup.profession = payload.profession;
      state.signup.selfEmployed = payload.selfEmployed;
    },

    setProfilingCustomerCapitalOrigin(state, payload) {
      state.signup.capitalOrigin = payload;
    },

    setProfilingCustomerNetWorth(state, payload) {
      state.signup.netWorth = payload;
    },

    setProfilingCustomerSalaryRange(state, payload) {
      state.signup.salaryRange = payload;
    },

    setProfilingCustomerDeclarations(state, payload) {
      state.signup.isPep = payload.isPep;
      state.signup.isCrs = payload.isCrs;
      state.signup.isFatca = payload.isFatca;
      state.signup.capitalOriginCountry = payload.capitalOriginCountry;
      state.signup.taxIdentificationNumbers = [{ ...payload.taxIdentificationNumber }];
    },

    setContractReinvestDividends(state, payload) {
      state.signup.reinvestDividends = payload;
    },

    setInvestData(state, payload) {
      state.investData = payload;
    },

    setDividendDeclarationAnswer(state, payload) {
      state.dividendDeclarationAnswer = payload;
    },

    setLoadingCustomerDividends(state, payload) {
      state.loadingCustomerDividends = payload;
    },

    setCustomerDividendsData(state, payload) {
      state.customerDividendsData = payload;
    },

    setInvestTime(state, payload) {
      state.investTime = payload;
    },

    setBankAccount(state, payload) {
      const currentAccount = state.profileData.bankAccounts.find(bankAccount => bankAccount?.currency === "CLP" || !bankAccount?.currency);

      state.profileData = {
        ...state.profileData,
        bankAccounts: [
          {
            ...currentAccount,
            ...payload,
          },
        ],
      };
    },

    setAccountData(state, payload) {
      // Update the state
      state.profileData = {
        ...state.profileData,
        ...payload,
      };
    },

    setSpouseData(state, { conjugalRegime, spouseData }) {
      // Update the state
      state.profileData = {
        ...state.profileData,
        conjugalRegime,
        spouses: [{ ...spouseData }],
      };
    },

    setContactAddressData(state, { addressData, phoneData }) {
      // Update the state
      state.profileData = {
        ...state.profileData,
        phones: [{ ...phoneData }],
        addresses: [{ ...addressData }],
      };
    },

    setDeclarationsData(state, { declarationsData, taxIdentificationNumberData }) {
      // Update the state
      state.profileData = {
        ...state.profileData,
        ...declarationsData,
        taxIdentificationNumbers: [{ ...taxIdentificationNumberData }],
      };
    },

    // Route stack mutations

    addToRouteStack(state, payload) {
      state.routeStack.push(payload);
    },

    popFromRouteStack(state) {
      state.routeStack.pop();
    },

    cleanRouteStack(state) {
      state.routeStack = [];
    },

    resetMutationErrors(state) {
      state.mutationErrors = [];
    },

    popFromMutationErrors(state) {
      state.mutationErrors.pop();
    },

    deleteFromMutationErrors(state, payload) {
      state.mutationErrors = state.mutationErrors.filter(error => error.name !== payload);
    },

  },

  actions: defaultState.actions,
  getters: defaultState.getters,
  plugins: [vuexLocal.plugin],
});
