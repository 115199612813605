var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"movements",attrs:{"fluid":""}},[_c('div',{staticClass:"max-width-wrapper",class:{
      'mt-3 px-16': _vm.$vuetify.breakpoint.mdAndUp,
      'px-3': _vm.$vuetify.breakpoint.smAndDown,
    }},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"movements-title ma-5"},[_vm._v(" "+_vm._s(this.$i18n.t("movements.title"))+" ")])])],1),_c('v-col',{class:{
        'mx-auto': _vm.$vuetify.breakpoint.mdAndUp,
        'mx-auto px-0': _vm.$vuetify.breakpoint.smAndDown,
      },attrs:{"cols":"12"}},[_c('v-card',{attrs:{"id":"movements-card"}},[_c('v-card-title',[_c('v-col',[(!_vm.loadingMovements)?_c('FundsFilter',{attrs:{"handleChangeFilterArray":_vm.updateFilterOptionsArray,"includedFunds":_vm.includedFunds}}):_vm._e()],1)],1),_c('v-data-table',{class:{ 'px-12': _vm.$vuetify.breakpoint.lgAndUp },attrs:{"items":_vm.movements,"headers":_vm.tableHeaders,"loading":_vm.loadingMovements,"disable-sort":"","mobile-breakpoint":"0","hide-default-header":"","search":_vm.filterOptionsString,"custom-filter":_vm.filterMovements,"footer-props":_vm.footerProps},scopedSlots:_vm._u([{key:`item.series.fund.shortName`,fn:function({ item }){return [_c('div',{staticClass:"table-date"},[_vm._v(_vm._s(_vm._f("formatDate")(item.settlementDate)))]),_c('br'),_c('v-avatar',{staticClass:"table-avatar-offset",attrs:{"color":_vm._f("addFundColor")(item.series.fund.run),"size":"48"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.series.fund.acronym))])])]}},{key:`item.movementType`,fn:function({ item }){return [_c('div',{staticClass:"movement-title-table"},[(
                  item.movementType === 'DISTRIBUTION'
                    && item.settlementDate >= _vm.todayDate
                )?_c('div',[_vm._v(" "+_vm._s(_vm.$i18n.t("movements.status.fulfilled.PENDING_DISTRIBUTION", { date: _vm.todayDate, }))+" "),(
                    item.movementType === 'DISTRIBUTION'
                      && item.settlementDate >= _vm.todayDate
                  )?_c('v-tooltip',{staticClass:"my-0",attrs:{"max-width":"350","right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey","dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$i18n.t("movements.status.tooltip.pendingDistribution", { date: _vm.$options.filters.formatDate(item.settlementDate), }))+" ")])]):_vm._e()],1):_c('div',[_vm._v(" "+_vm._s(_vm.$i18n.t("movements.status.fulfilled." + item.movementType))+" "),(item.movementType === 'PENDING_REDEMPTION')?_c('v-tooltip',{staticClass:"my-0",attrs:{"max-width":"350","right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey","dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t("movements.status.tooltip.pendingRedemption")))])]):_vm._e()],1)]),_c('div',{staticClass:"bluer"},[_vm._v(_vm._s(item.series.fund.shortName))])]}},{key:`item.amount`,fn:function({ item }){return [_c('div',{staticClass:"table-number"},[_vm._v(" "+_vm._s(_vm.getMovementDirection(item.movementType) === "in" ? "+" : "-")+"$"+_vm._s(_vm._f("currencyNoSymbol")(item.currencyAmount))+" ")])]}}],null,true)})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }