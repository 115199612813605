export const googleRecaptcha = {
  data: () => ({
    failedCaptcha: false,
  }),

  methods: {
    async verifyRecaptcha(action) {
      // We ignore recaptcha when running tests
      if (window.Cypress) {
        return true;
      }
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha(action);
      const response = await this.axios.post(process.env.VUE_APP_GOOGLE_RECAPTCHA_VALIDATOR_URL, {
        captchaResponse: token,
      }, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      const isHuman = response?.data?.isHuman;
      // To test fail message comment previous code and uncomment the one below
      // console.log(action);
      // const isHuman = false;
      if (!isHuman) {
        this.failedCaptcha = true;
      }
      return isHuman;
    },
  },
};
