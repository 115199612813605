export const customerGQLQueries = {
  getProfilesFromEmail(email) {
    return {
      operationName: "getProfilesFromEmail",
      query: `query getProfilesFromEmail($email: String) {
        emails(email: $email) {
          id
          email
          principal
          customer {
            id
            name
            lastName
            identifier
          }
        }
      }
      `,
      variables: {
        email,
      },
    };
  },

  getCustomerProfile({ accountId }) {
    return {
      operationName: "getCustomerProfile",
      query: `query getCustomerProfile($id: ID) {
        profile(id: $id) {
          id
          name
          lastName
          identifier
          documentNumber
          gender
          birthdate
          entityType
          profiling
          maritalStatus
          conjugalRegime
          capitalOrigin
          occupation
          profession
          selfEmployed
          nationality
          birthCountry
          isPep
          isCrs
          isFatca
          capitalOriginCountry
          reinvestDistributions
          salaryRange
          netWorth
          documentExpirationDate
          taxIdentificationNumbers{
            id
            country
            identification
          }
          emails {
            principal
            email
          }
          spouses {
            id
            identifier
            name
            lastName
          }
          phones {
            phone
          }
          addresses {
            streetName
            houseNumber
            interiorNumber
            houseType
            locality
            region
            country
          }
          bankAccounts {
            accountNumber
            updatedAt
            bank {
              name
              id
            }
            currency
            id
            bankAccountType {
              id
              typeName
            }
          }
        }
      }`,
      variables: {
        id: accountId,
      },
    };
  },

  getCustomerData({ accountId }) {
    return {
      operationName: "getCustomerData",
      query: `query getCustomerData($id: ID) {
        profile(id: $id) {
          id
          name
          lastName
          identifier
          documentNumber
          gender
          birthdate
          entityType
          profiling
          maritalStatus
          conjugalRegime
          capitalOrigin
          selfEmployed
          nationality
          birthCountry
          occupation
          profession
          isPep
          isCrs
          isFatca
          capitalOriginCountry
          reinvestDistributions
          salaryRange
          netWorth
          documentExpirationDate
          taxIdentificationNumbers{
            id
            country
            identification
          }
          emails {
            principal
            email
          }
          spouses {
            id
            identifier
            name
            lastName
          }
          bankAccounts {
            accountNumber
            accountType
            updatedAt
            bank {
              name
              id
            }
            currency
            id
            bankAccountType {
              id
              typeName
            }
          }
          phones {
            phone
          }
          addresses {
            streetName
            houseNumber
            interiorNumber
            houseType
            locality
            region
            country
          }
        }
        funds(runs: [9395, 9118, 9725, 9937, 9958, 9994, 10074, 10275, 10348]) {
          id
          run
          name
          fantasyName
          acronym
          shortName
          identifier
          infoUrl
          admin {
            fantasyName
          }
          fundBankAccounts {
            accountNumber
            currency
            bank {
              name
            }
          }
          series {
            id
            name
            fantasyName
            currency
            symbol
            balances(currency: CLP, accountId: $id) {
              currencyAmount
              quotasAmount
              balanceType
              currency
              quotaValue
              exchangeRate
              date
            }
          }
        }
      }`,
      variables: {
        id: accountId,
      },
    };
  },

  getCustomerMovementsData({ accountId }) {
    return {
      operationName: "getCustomerData",
      query: `query getCustomerData($id: ID) {
        funds(runs: [9395, 9118, 9725, 9937, 9958, 9994, 10074, 10275, 10348]) {
          id
          run
          name
          fantasyName
          acronym
          shortName
          identifier
          series {
            id
            name
            currency
            symbol
            movements(currency: CLP, accountId: $id) {
              id
              quotasAmount
              currency
              currencyAmount
              settlementDate
              movementType
            }
          }
        }
      }`,
      variables: {
        id: accountId,
      },
    };
  },

  getBalanceHistoryData({ accountId }) {
    return {
      operationName: "getBalanceHistoryData",
      query: `query getBalanceHistoryData($accountId: ID) {
        funds(runs: [9395, 9118, 9725, 9937, 9958, 9994, 10275, 10348]) {
          id
          run
          name
          acronym
          shortName
          infoUrl
          series {
            id
            name
            fantasyName
            balancesHistory(accountId: $accountId, currency: CLP, balanceTypes: [CURRENT, DEPOSITS_WITHDRAWALS]) {
              balanceType
              currency
              balanceHistoryPoints {
                date
                quotasAmount
                currencyAmount
              }
            }
          }
        }
      }`,
      variables: {
        accountId,
      },
    };
  },

  changePassword({ oldPassword, newPassword }) {
    return {
      operationName: "changePassword",
      query: `mutation changePassword($oldPassword: String!, $newPassword: String!) {
        changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
          success
        }
      }`,
      variables: {
        oldPassword,
        newPassword,
      },
    };
  },

  patchCustomerData({
    id,
    customerInput,
    phoneInput,
    addressInput,
    bankAccountInput,
    taxIdentificationNumberInput,
    spouseInput,
  }) {
    return {
      operationName: "patchCustomerData",
      query: `mutation patchCustomerData(
        $id: ID!,
        $customerInput: CustomerInput,
        $phoneInput: PhoneInput,
        $addressInput: AddressInput,
        $bankAccountInput: BankAccountInput,
        $taxIdentificationNumberInput: TaxIdentificationNumberInput,
        $spouseInput: SpouseInput
        ) {
          patchCustomerData(
            id: $id,
            customerInput: $customerInput,
            phoneInput: $phoneInput,
            addressInput: $addressInput,
            bankAccountInput: $bankAccountInput,
            taxIdentificationNumberInput: $taxIdentificationNumberInput,
            spouseInput: $spouseInput) {
              addresses {
                id
              }
              phones {
                id
              }
              customers {
                id
              }
              customerAccounts {
                id
              }
            }
        }
      `,
      variables: {
        id,
        customerInput,
        phoneInput,
        addressInput,
        bankAccountInput,
        taxIdentificationNumberInput,
        spouseInput,
      },
    };
  },

  uploadIdentificationDocument({
    customerId,
    frontIdentificationDocument,
    backIdentificationDocument,
  }) {
    return {
      operationName: "uploadIdentificationDocument",
      query: `mutation uploadIdentificationDocument(
        $customerId: ID!,
        $frontIdentificationDocument: Upload!,
        $backIdentificationDocument: Upload!,
      ){
        uploadIdentificationDocument(
          customerId: $customerId
          frontIdentificationDocument: $frontIdentificationDocument
          backIdentificationDocument: $backIdentificationDocument
        ) {
          success
        }
      }
        `,
      variables: {
        customerId,
        frontIdentificationDocument,
        backIdentificationDocument,
      },
    };
  },

  createSpouse({
    customerId, identifier, name, lastName,
  }) {
    return {
      operationName: "createSpouse",
      query: `mutation createSpouse(
        $customerId: ID!,
        $identifier: String!,
        $name: String!,
        $lastName: String!

      ){
        createSpouse(
          customerId: $customerId,
          identifier: $identifier,
          name: $name,
          lastName: $lastName
        ){
          id,
          identifier,
          name,
          lastName
        }
      }
        `,
      variables: {
        customerId,
        identifier,
        name,
        lastName,
      },
    };
  },

  patchSpouse({
    id, identifier, name, lastName,
  }) {
    return {
      operationName: "patchSpouse",
      query: `mutation patchSpouse(
        $id: ID!,
        $identifier: String,
        $name: String,
        $lastName: String

      ){
        patchSpouse(
          id: $id,
          identifier: $identifier,
          name: $name,
          lastName: $lastName
        ){
          id,
          identifier,
          name,
          lastName
        }
      }
        `,
      variables: {
        id,
        ...(identifier && { identifier }),
        ...(name && { name }),
        ...(lastName && { lastName }),

      },
    };
  },

  getCustomerStatus({
    customerIdentifier,
    email,
  }) {
    return {
      operationName: "customerStatus",
      query: `query customerStatus(
        $customerIdentifier: String,
        $email: String,
      ) {
        customerStatus(customerIdentifier: $customerIdentifier, email: $email)
        {
          rut
          completedValidation
          completedIdentification
          completedProfiling
          completedContract
          completedContractCompass
          compassContractDate
          contractDate
          pendingProfilingIdentificationReview
          acceptedProfilingIdentificationReview
        }
      }`,
      variables: {
        ...(customerIdentifier && { customerIdentifier }),
        ...(email && { email }),
      },
    };
  },

  createAccountStatement({ customerId, reportDate }) {
    return {
      operationName: "createAccountStatement",
      query: `mutation createAccountStatement($customerId: ID!, $reportDate: String!)  {
        createAccountStatement(customerId: $customerId, reportDate: $reportDate) {
          success
          file
        }
      }
      `,
      variables: {
        customerId,
        reportDate,
      },
    };
  },

  createTaxIdentificationNumber({
    customerId, country, identification,
  }) {
    return {
      operationName: "createTaxIdentificationNumber",
      query: `mutation createTaxIdentificationNumber(
        $customerId: ID!,
        $country: String!,
        $identification: String!,
      ){
        createTaxIdentificationNumber(
          customerId: $customerId,
          country: $country,
          identification: $identification,
        ){
          id,
          country,
          identification,
        }
      }
        `,
      variables: {
        customerId,
        country,
        identification,
      },
    };
  },

  patchTaxIdentificationNumber({ id, country, identification }) {
    return {
      operationName: "patchTaxIdentificationNumber",
      query: `mutation patchTaxIdentificationNumber(
        $id: ID!,
        $country: String,
        $identification: String,
      ){
        patchTaxIdentificationNumber(
          id: $id,
          country: $country,
          identification: $identification,
        ){
          id,
          country
          identification
        }
      }
        `,
      variables: {
        id,
        ...(country && { country }),
        ...(identification && { identification }),
      },
    };
  },

  signCompassContract({
    customerId,
  }) {
    return {
      operationName: "signCompassContract",
      query: `mutation signCompassContract(
        $customerId: ID!,
      ){
        signCompassContract(
          customerId: $customerId
        ) {
          success
        }
      }
        `,
      variables: {
        customerId,
      },
    };
  },

  signUpValidation({
    email,
    customerIdentifier,
    documentNumber,
    front,
    back,
  }) {
    return {
      operationName: "signUpValidation",
      query: `mutation signUpValidation(
        $email: String!
        $customerIdentifier: String!
        $documentNumber: String!
        $front: Upload!
        $back: Upload!
      ){
        signUpValidation(
          email: $email,
          customerIdentifier: $customerIdentifier,
          documentNumber: $documentNumber,
          front: $front,
          back: $back,
        ) {
          success
        }
      }
        `,
      variables: {
        email,
        customerIdentifier,
        documentNumber,
        front,
        back,
      },
    };
  },

  signUpIdentification({
    email,
    name,
    lastName,
    gender,
    birthdate,
    birthCountry,
    nationality,
    phone,
    country,
    region,
    locality,
    street,
    houseNumber,
    interiorNumber,
    maritalStatus,
    spouseName,
    spouseLastName,
    spouseIdentifier,
    conjugalRegime,
  }) {
    return {
      operationName: "signUpIdentification",
      query: `mutation signUpIdentification(
        $email: String!
        $name: String!
        $lastName: String!
        $gender: GenderType!
        $birthdate: String!
        $birthCountry: String!
        $nationality: String!
        $phone: String!
        $country: String!
        $region: String!
        $locality: String!
        $street: String!
        $houseNumber: String!
        $interiorNumber: String
        $maritalStatus: MaritalStatus!
        $spouseName: String
        $spouseLastName: String
        $spouseIdentifier: String
        $conjugalRegime: ConjugalRegime
      ){
        signUpIdentification(
          email: $email,
          name: $name,
          lastName: $lastName,
          gender: $gender,
          birthdate: $birthdate,
          birthCountry: $birthCountry,
          nationality: $nationality,
          phone: $phone,
          country: $country,
          region: $region,
          locality: $locality,
          street: $street,
          houseNumber: $houseNumber,
          interiorNumber: $interiorNumber,
          maritalStatus: $maritalStatus,
          spouseName: $spouseName,
          spouseLastName: $spouseLastName,
          spouseIdentifier: $spouseIdentifier,
          conjugalRegime: $conjugalRegime
        ) {
          success
        }
      }
        `,
      variables: {
        email,
        name,
        lastName,
        gender,
        birthdate,
        birthCountry,
        nationality,
        phone,
        country,
        region,
        locality,
        street,
        houseNumber,
        maritalStatus,
        ...(interiorNumber && { interiorNumber }),
        ...(spouseName && { spouseName }),
        ...(spouseLastName && { spouseLastName }),
        ...(spouseIdentifier && { spouseIdentifier }),
        ...(conjugalRegime && { conjugalRegime }),
      },
    };
  },

  signUpProfiling({
    email,
    profiling,
    capitalOrigin,
    capitalOriginCountry,
    netWorth,
    salaryRange,
    occupation,
    profession,
    selfEmployed,
    isPep,
    isFatca,
    isCrs,
    taxIdentificationNumber,
    referredFrom,
  }) {
    return {
      operationName: "signUpProfiling",
      query: `mutation signUpProfiling(
        $email: String!
        $profiling: Int!
        $capitalOrigin: CapitalOrigin!
        $capitalOriginCountry: String!
        $netWorth: NetWorth!
        $salaryRange: SalaryRange
        $occupation: String!
        $profession: String!
        $selfEmployed: Boolean!
        $isPep: Boolean!
        $isFatca: Boolean!
        $isCrs: Boolean!
        $taxIdentificationNumber: [TaxIdentificationNumberInput]
        $referredFrom: String!
      ){
        signUpProfiling(
          email: $email,
          profiling: $profiling,
          capitalOrigin: $capitalOrigin,
          capitalOriginCountry: $capitalOriginCountry,
          netWorth: $netWorth,
          occupation: $occupation,
          profession: $profession,
          selfEmployed: $selfEmployed,
          salaryRange: $salaryRange,
          isPep: $isPep,
          isFatca: $isFatca,
          isCrs: $isCrs,
          taxIdentificationNumber: $taxIdentificationNumber,
          referredFrom: $referredFrom
        ) {
          success
        }
      }
        `,
      variables: {
        email,
        profiling,
        capitalOrigin,
        capitalOriginCountry,
        netWorth,
        salaryRange,
        occupation,
        profession,
        selfEmployed,
        isPep,
        isFatca,
        isCrs,
        ...(taxIdentificationNumber && { taxIdentificationNumber }),
        referredFrom,
      },
    };
  },

  signUpContract({
    email,
    reinvestDividends,
    origin,
  }) {
    return {
      operationName: "signUpContract",
      query: `mutation signUpContract(
        $email: String!
        $reinvestDividends: Boolean!
        $origin: String!
      ){
        signUpContract(
          email: $email,
          reinvestDividends: $reinvestDividends,
          origin: $origin
        ) {
          success
        }
      }
        `,
      variables: {
        email,
        reinvestDividends,
        origin,
      },
    };
  },

  createCustomerDeclaration({
    customerIdentifier,
    declaration,
    response,
  }) {
    return {
      operationName: "createCustomerDeclaration",
      query: `mutation createCustomerDeclaration(
        $customerIdentifier: String!
        $declaration: String!
        $response: String!
      ){
        createCustomerDeclaration(
          customerIdentifier: $customerIdentifier,
          declaration: $declaration,
          response: $response
        ) {
          success
        }
      }
        `,
      variables: {
        customerIdentifier,
        declaration,
        response,
      },
    };
  },

};
