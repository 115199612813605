/* eslint-disable object-curly-newline */
export const operationsGQLQueries = {
  createRedemption({ requester, requestSource, requestedAmount,
    requestedAmountCurrency, customerIdentifier, fundSeriesSymbol }) {
    return {
      operationName: "createRedemption",
      query: `mutation createRedemption (
        $requester: String!,
        $requestSource: String!,
        $requestedAmount: Float!,
        $requestedAmountCurrency: String!,
        $customerIdentifier: String!,
        $fundSeriesSymbol: String!,
      ) {
        createRedemption(
            requester: $requester,
            requestSource: $requestSource,
            requestedAmount: $requestedAmount,
            requestedAmountCurrency: $requestedAmountCurrency,
            customerIdentifier: $customerIdentifier,
            fundSeriesSymbol: $fundSeriesSymbol,
            ) {
              id
              requester,
              requestSource,
              requestDate,
              integrationDate,
              settlementDate,
              quotasAmount,
              requestedAmount,
              requestedAmountCurrency,
              paidAmount,
              paidAmountCurrency,
              traceType,
              status,
              integrationStatus
              customer {
                firstName
                lastName
              }
              fundSeries {
                symbol
              }
            }
        }
      `,
      variables: {
        ...(requester && { requester }),
        ...(requestSource && { requestSource }),
        ...(requestedAmount && { requestedAmount }),
        ...(requestedAmountCurrency && { requestedAmountCurrency }),
        ...(customerIdentifier && { customerIdentifier }),
        ...(fundSeriesSymbol && { fundSeriesSymbol }),
      },
    };
  },

  createReinvestment({
    requester,
    requestSource,
    requestedAmount,
    requestedAmountCurrency,
    customerIdentifier,
    originFundSeriesSymbol,
    destinationFundSeriesSymbol,
  }) {
    return {
      operationName: "createReinvestment",
      query: `mutation createReinvestment (
        $requester: String!,
        $requestSource: String!,
        $requestedAmount: Float!,
        $requestedAmountCurrency: String!,
        $customerIdentifier: String!,
        $originFundSeriesSymbol: String!,
        $destinationFundSeriesSymbol: String!,
      ) {
        createReinvestment(
          requester: $requester,
          requestSource: $requestSource,
          requestedAmount: $requestedAmount,
          requestedAmountCurrency: $requestedAmountCurrency,
          customerIdentifier: $customerIdentifier,
          originFundSeriesSymbol: $originFundSeriesSymbol,
          destinationFundSeriesSymbol: $destinationFundSeriesSymbol)
        {
          id
          requestedAmount
          requestedAmountCurrency
          quotasAmount
          requestDate
          executionDate
          integrationDate
          settlementDate
          customer {
            firstName
            lastName
          }
          originFundSeries {
            symbol
          }
          destinationFundSeries {
            symbol
          }
          requester
          requestSource
          status
          traceType
          integrationStatus
        }
      }

      `,
      variables: {
        ...(requester && { requester }),
        ...(requestSource && { requestSource }),
        ...(requestedAmount && { requestedAmount }),
        ...(requestedAmountCurrency && { requestedAmountCurrency }),
        ...(customerIdentifier && { customerIdentifier }),
        ...(originFundSeriesSymbol && { originFundSeriesSymbol }),
        ...(destinationFundSeriesSymbol && { destinationFundSeriesSymbol }),
      },
    };
  },

  createPaymentIntent({ fundRun, amount, customerIdentifier }) {
    return {
      operationName: "createPaymentIntent",
      query: `mutation createPaymentIntent (
        $fundRun: String!,
        $amount: Int!,
        $customerIdentifier: String!
      ) {
        createPaymentIntent(
          fundRun: $fundRun,
          amount: $amount,
          customerIdentifier: $customerIdentifier
        ) {
          id
          object
          amount
          currency
          widgetToken
          status
          referenceId
          recipientAccount {
            holderId
            number
            type
            institutionId
          }
          senderAccount {
            holderId
            number
            type
            institutionId
          }
          createdAt
        }
      }`,
      variables: {
        fundRun: fundRun.toString(),
        amount,
        customerIdentifier,
      },
    };
  },

  patchPaymentIntent({ fintocId, status }) {
    return {
      operationName: "patchPaymentIntent",
      query: `mutation patchPaymentIntent (
        $fintocId: ID!,
        $status: PaymentIntentStatus!,
      ) {
        patchPaymentIntent(
          fintocId: $fintocId,
          status: $status,
        ) {
          id
          object
          amount
          currency
          widgetToken
          status
          referenceId
          recipientAccount {
            holderId
            number
            type
            institutionId
          }
          senderAccount {
            holderId
            number
            type
            institutionId
          }
          createdAt
        }
      }`,
      variables: {
        fintocId,
        status,
      },
    };
  },

  getPendingMovements({ customerIdentifier, fundRuns, currency, includeToday }) {
    return {
      operationName: "getPendingMovements",
      query: `query getPendingMovements (
        $customerIdentifier: String!,
        $fundRuns: [ID],
        $currency: String,
        $includeToday: Boolean
      )  {
        customer(
         identifier: $customerIdentifier,
        )
      {
        identifier
        firstName
        lastName
        pendingMovements(fundRuns: $fundRuns, currency: $currency, includeToday: $includeToday) {
          id
          movementType
          fundSeries {
            symbol
            fundRun
            seriesName
          }
          executionDate
          integrationDate
          settlementDate
          currency
          currencyAmount
          status
          requestedInCLP
          requestedInUSD
          requestedInShares
        }
      }
    }
      `,
      variables: {
        ...(fundRuns && { fundRuns }),
        ...(currency && { currency }),
        ...(includeToday && { includeToday }),
        ...(customerIdentifier && { customerIdentifier }),

      },
    };
  },

  getPendingReinvestments({ customerIdentifier }) {
    return {
      operationName: "getPendingReinvestments",
      query: `query getPendingReinvestments($customerIdentifier: String) {
        reinvestments(customerIdentifier: $customerIdentifier) {
          id
          customerIdentifier
          originFundSeriesSymbol
          originFundRun
          destinationFundSeriesSymbol
          destinationFundRun
          executionDate
          settlementDate
          integrationDate
          subscriptionExecutionDate
          quotasAmount
          requestedCurrency
          requestedAmount
          requestedInCLP
          requestedInUSD
          requestedInShares
          status
          statusCode
          movementType
        }
      }`,
      variables: {
        ...(customerIdentifier && { customerIdentifier }),
      },
    };
  },

  getReinvestments({ customerIdentifier, fundRuns }) {
    return {
      operationName: "getReinvestments",
      query: `query getReinvestments (
        $customerIdentifier: String
        $originFundRuns: [ID],
        $destinationFundRuns: [ID],
      ) {
        reinvestments(
          customerIdentifier: $customerIdentifier
          originFundRuns: $originFundRuns
          destinationFundRuns: $destinationFundRuns
        ) {
          id
          requestedAmount
          requestedAmountCurrency
          quotasAmount
          requestDate
          executionDate
          integrationDate
          settlementDate
          customer {
            firstName
            lastName
          }
          originFundSeries {
            symbol
            fundRun
          }
          destinationFundSeries {
            symbol
            fundRun
          }
          requester
          requestSource
          status
          traceType
          integrationStatus
        }
      }
      `,
      variables: {
        ...(customerIdentifier && { customerIdentifier }),
        ...(fundRuns && { destinationFundRuns: fundRuns, originFundRuns: fundRuns }),
      },
    };
  },

  createDividendAnswer(
    { customerDividendId,
      dividendOptionValue,
      dividendOptionId,
      answeredByName,
      answeredByIdentifier,

    },
  ) {
    return {
      query: `mutation createDividendAnswer(
        $customerDividendId: ID!
        $dividendOptionValue: String
        $dividendOptionId: ID
        $answeredByName: String
        $answeredByIdentifier: String
      ){
        createDividendAnswer(
          customerDividendId: $customerDividendId
          dividendOptionValue: $dividendOptionValue
          dividendOptionId: $dividendOptionId
          answeredByName: $answeredByName
          answeredByIdentifier: $answeredByIdentifier
        ) {
            id
            customerDividend {
              customer {
                firstName
                lastName
              }
              fundSeriesDividend {
                fundSeries {
                  symbol
                  fundRun
                  seriesName
                }
                fundDividend {
                  declarationDate
                  paymentDate
                  reinvestmentReceiptDate
                  dividendOptions {
                    id
                    value
                  }
                }
              }
              dividendAnswer {
                dividendOption {
                  id
                  value
                }
              }
            }
          }
        }
      `,
      variables: {
        ...(customerDividendId && { customerDividendId }),
        ...(dividendOptionValue && { dividendOptionValue }),
        ...(dividendOptionId && { dividendOptionId }),
        ...(answeredByName && { answeredByName }),
        ...(answeredByIdentifier && { answeredByIdentifier }),
      },
    };
  },

  getCustomerDividends({ customerIdentifier, fromDate }) {
    const dateFilter = { operation: "ge", value: fromDate };
    return {
      operationName: "getCustomerDividends",
      query: `query getCustomerDividends(
        $customerIdentifier: String
        $dateFilter: DateFilter
      ) {
        customerDividends(
          customerIdentifier: $customerIdentifier
          declarationDate: $dateFilter
        ) {
          id
          customer {
            firstName
            lastName
          }

          fundSeriesDividend {
            fundSeries {
              symbol
              fundRun
              seriesName
            }
            fundDividend {
              declarationDate
              paymentDate
              reinvestmentReceiptDate
              dividendOptions {
                id
                value
              }
            }
          }
          dividendAnswer{
            answeredAt
            dividendOption{
              value
            }
          }

        }
      }
      `,
      variables: {
        ...(customerIdentifier && { customerIdentifier }),
        ...(fromDate && { dateFilter }),
      },
    };
  },

};
