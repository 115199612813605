/* eslint-disable import/order */
/* eslint-disable no-restricted-globals */
import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js";
import Home from "../views/Home.vue";
import Receipts from "../views/Movements.vue";
import Login from "../views/Login.vue";
import Callback from "../views/Callback.vue";

// Profile
import AccountView from "../views/profile/AccountView.vue";
import ProfilingStart from "@/views/profile/ProfilingStart.vue";
import PersonalData from "../views/profile/PersonalData.vue";
import BankingData from "../views/profile/BankingData.vue";
import RiskProfile from "../views/profile/RiskProfile.vue";
import EditRiskProfile from "../views/profile/EditRiskProfile.vue";
import Identification from "../views/profile/Identification.vue";
import ChangePassword from "../views/profile/ChangePassword.vue";
import ContactAddress from "../views/profile/ContactAddress.vue";
import InvestmentCertificates from "../views/profile/InvestmentCertificates.vue";
import DocumentsView from "../views/profile/DocumentsView.vue";
import PendingContracts from "../views/profile/PendingContracts.vue";
import DeclarationsView from "../views/profile/DeclarationsView.vue";
import PasswordRecoverySuccess from "../views/profile/PasswordRecoverySuccess.vue";
import ContractSignatureSuccess from "../views/profile/ContractSignatureSuccess.vue";
import UpdateIdDocument from "../views/profile/UpdateIdDocument.vue";
import AccountCompassCompleteData from "../views/profile/CompassCompleteData.vue";
import AccountCompassCompleteDataBeforeContract from "../views/profile/CompassCompleteDataBeforeContract.vue";

// Transactional profile
import TransactionalProfileIntro from "../views/profile/transactional-profile/TransactionalProfileIntro.vue";
import TransactionalProfileQuestions from "../views/profile/transactional-profile/TransactionalProfileQuestions.vue";

// Signup
import SignupView from "../views/SignupView.vue";
import SignupDashboard from "../views/signup/SignupDashboard.vue";
import SignupUnderMaintenance from "../views/signup/SignupUnderMaintenance.vue";
// Signup - Identification
import IdentificationView from "../views/signup/identification/IdentificationView.vue";
import PersonalView from "../views/signup/identification/PersonalView.vue";
import SpouseView from "../views/signup/identification/SpouseView.vue";
import ContactView from "../views/signup/identification/ContactView.vue";
// Signup - Profiling
import ProfilingIntro from "../views/signup/profiling/ProfilingIntro.vue";
import ProfilingQuestion from "../views/signup/profiling/ProfilingQuestion.vue";
import ProfilingResult from "../views/signup/profiling/ProfilingResult.vue";
import CapitalOrigin from "../views/signup/profiling/CapitalOrigin.vue";
import NetWorth from "../views/signup/profiling/NetWorth.vue";
import SalaryRange from "../views/signup/profiling/SalaryRange.vue";
import Occupation from "../views/signup/profiling/Occupation.vue";
import OtherDeclarations from "../views/signup/profiling/OtherDeclarations.vue";
// Signup - Contract
import ContractView from "../views/signup/contract/ContractView.vue";
// Signup - Shared
import DataVerification from "../views/signup/shared/DataVerification.vue";
import ErrorView from "../views/ErrorView.vue";

// Investment
import ViewFunds from "../views/investment/ViewFunds.vue";
import FundDetails from "../views/investment/FundDetails.vue";
import ChooseInvestmentAmount from "../views/investment/ChooseInvestmentAmount.vue";
import InvestCompassCompleteData from "../views/investment/CompassCompleteData.vue";
import BankSelect from "../views/investment/BankSelect.vue";
import SelectTransferOption from "../views/investment/SelectTransferOption.vue";
import BankTransfer from "../views/investment/BankTransfer.vue";
import InvestmentSuccess from "../views/investment/InvestmentSuccess.vue";
import InvestmentDetails from "../views/investment/InvestmentDetails.vue";
import CompassSignatureNotice from "../views/investment/CompassSignatureNotice.vue";
// Redemptions
import ChooseRedemptionFlow from "../views/redemptions/ChooseRedemptionFlow.vue";
import RedemptionReasons from "../views/redemptions/RedemptionReasons.vue";
import RedemptionOrigin from "../views/redemptions/RedemptionOrigin.vue";
import FromFundRedemption from "../views/redemptions/FromFundRedemption.vue";
import ChooseRedemptionAmount from "../views/redemptions/ChooseRedemptionAmount.vue";
import ConfirmBankAccount from "../views/redemptions/ConfirmBankAccount.vue";
import RedemptionSummary from "../views/redemptions/RedemptionSummary.vue";
import RedemptionReinvestmentSuccess from "../views/redemptions/RedemptionReinvestmentSuccess.vue";
// Reinvestment
import SelectOriginFund from "../views/reinvestment/SelectOriginFund.vue";
import SelectDestinationFund from "../views/reinvestment/SelectDestinationFund.vue";
import ChooseReinvestmentAmount from "../views/reinvestment/ChooseReinvestmentAmount.vue";
import ReinvestmentSummary from "../views/reinvestment/ReinvestmentSummary.vue";
// Dividends
import ChooseDividendOption from "../views/dividends/ChooseDividendOption.vue";
import ConfirmDividendDeclaration from "../views/dividends/ConfirmDividendDeclaration.vue";
import DividendDeclarationSuccess from "../views/dividends/DividendDeclarationSuccess.vue";
import DepositDividend from "../views/dividends/DepositDividend.vue";
import ExpiredIdDocumentNotice from "../views/dividends/ExpiredIdDocumentNotice.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      requiresAuth: true,
      showNavigation: true,
    },
  },

  {
    path: "/dividends/choose-option",
    name: "dividendsChooseDividendOption",
    component: ChooseDividendOption,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/dividends/expired-id-document-notice",
    name: "expiredIdDocumentNotice",
    component: ExpiredIdDocumentNotice,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/dividends/confirm-declaration",
    name: "confirmDividendDeclaration",
    component: ConfirmDividendDeclaration,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/dividends/declaration-success",
    name: "dividendsDeclarationSuccess",
    component: DividendDeclarationSuccess,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/dividends/deposit",
    name: "depositDividend",
    component: DepositDividend,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/details",
    name: "details",
    component: InvestmentDetails,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/details/:fundRun",
    name: "detailedFund",
    component: InvestmentDetails,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/movements",
    name: "movements",
    component: Receipts,
    meta: {
      requiresAuth: true,
      showNavigation: true,
    },
  },

  {
    path: "/account",
    name: "account",
    component: AccountView,
    meta: {
      requiresAuth: true,
      showNavigation: true,
      signup: true,
    },
  },

  {
    path: "/account/personal",
    name: "accountPersonal",
    component: PersonalData,
    meta: {
      requiresAuth: true,
      signup: true,
    },
  },

  {
    path: "/account/bank",
    name: "accountBank",
    component: BankingData,
    meta: {
      requiresAuth: true,
      showNavigation: true,
      signup: true,
    },
  },

  {
    path: "/account/declarations",
    name: "accountDeclarations",
    component: DeclarationsView,
    meta: {
      requiresAuth: true,
      signup: true,
    },
  },

  {
    path: "/account/risk-profile",
    name: "accountRiskProfile",
    component: RiskProfile,
    meta: {
      requiresAuth: true,
      showNavigation: true,
      signup: true,

    },
  },

  {
    path: "/account/edit-risk-profile",
    name: "accountEditRiskProfile",
    component: EditRiskProfile,
    meta: {
      requiresAuth: true,
      signup: true,
    },
  },

  {
    path: "/account/identification",
    name: "accountIdentification",
    component: Identification,
    meta: {
      requiresAuth: true,
      showNavigation: true,
      signup: true,
    },
  },

  {
    path: "/account/update-id-document",
    name: "accountUpdateIdDocument",
    component: UpdateIdDocument,
    meta: {
      requiresAuth: true,
      signup: true,
    },
  },

  {
    path: "/account/change-password",
    name: "accountChangePassword",
    component: ChangePassword,
    meta: {
      requiresAuth: true,
      signup: true,
    },
  },

  {
    path: "/account/contact-address",
    name: "accountContactAddress",
    component: ContactAddress,
    meta: {
      requiresAuth: true,
      signup: true,
    },
  },

  {
    path: "/account/investment-certificates",
    name: "accountInvestmentCertificates",
    component: InvestmentCertificates,
    meta: {
      requiresAuth: true,
      showNavigation: true,
      signup: true,
    },
  },

  {
    path: "/account/documents",
    name: "accountDocumentsView",
    component: DocumentsView,
    meta: {
      requiresAuth: true,
      showNavigation: true,
      signup: true,
    },
  },

  {
    path: "/account/documents/pending-contracts",
    name: "accountPendingContracts",
    component: PendingContracts,
    meta: {
      requiresAuth: true,
      showNavigation: true,
      signup: true,
    },

  },

  {
    path: "/account/password-recovery-success",
    name: "accountPasswordRecoverySuccess",
    component: PasswordRecoverySuccess,
    meta: {
      requiresAuth: true,
      showNavigation: true,
      signup: true,
    },
  },

  {
    path: "/signup",
    name: "signupView",
    component: SignupView,
    meta: {
      signup: true,
    },
  },

  {
    path: "/signup/under-maintenance",
    name: "signupUnderMaintenance",
    component: SignupUnderMaintenance,
    meta: {
      global: true,
    },
  },

  {
    path: "/signup/dashboard",
    name: "signupDashboard",
    component: SignupDashboard,
    meta: {
      signup: true,
    },
  },

  // Signup identification flow

  {
    path: "/signup/identification/intro",
    name: "signupIdentification",
    component: IdentificationView,
    meta: {
      signup: true,
      signupFlow: "identification",
    },
  },

  {
    path: "/signup/identification/personal",
    name: "signupIdentificationPersonal",
    component: PersonalView,
    meta: {
      signup: true,
      signupFlow: "identification",
    },
  },

  {
    path: "/signup/identification/spouse",
    name: "signupIdentificationSpouse",
    component: SpouseView,
    meta: {
      signup: true,
      signupFlow: "identification",
    },
  },

  {
    path: "/signup/identification/contact",
    name: "signupIdentificationContact",
    component: ContactView,
    meta: {
      signup: true,
      signupFlow: "identification",
    },
  },

  // Signup profiling flow

  {
    path: "/signup/profiling/intro",
    name: "signupProfilingIntro",
    component: ProfilingIntro,
    meta: {
      signup: true,
      signupFlow: "profiling",
    },
  },

  {
    path: "/account/profiling/start",
    name: "accountProfilingStart",
    component: ProfilingStart,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/signup/profiling/question/:currentQuestionNumber",
    name: "signupProfilingQuestion",
    props: true,
    component: ProfilingQuestion,
    meta: {
      signup: true,
      signupFlow: "profiling",
    },
  },

  {
    path: "/account/profiling/question/:currentQuestionNumber",
    name: "accountProfilingQuestion",
    props: true,
    component: ProfilingQuestion,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/signup/profiling/result",
    name: "signupProfilingResult",
    component: ProfilingResult,
    meta: {
      signup: true,
      signupFlow: "profiling",
    },
  },

  {
    path: "/account/profiling/result",
    name: "accountProfilingResult",
    component: ProfilingResult,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/signup/profiling/capital",
    name: "signupCapitalOrigin",
    component: CapitalOrigin,
    meta: {
      signup: true,
      signupFlow: "profiling",
    },
  },

  {
    path: "/signup/profiling/networth",
    name: "signupNetWorth",
    component: NetWorth,
    meta: {
      signup: true,
      signupFlow: "profiling",
    },
  },

  {
    path: "/signup/profiling/salary-range",
    name: "signupSalaryRange",
    component: SalaryRange,
    meta: {
      signup: true,
      signupFlow: "profiling",
    },
  },

  {
    path: "/signup/profiling/occupation",
    name: "signupOccupation",
    component: Occupation,
    meta: {
      signup: true,
      signupFlow: "profiling",
      showSubheader: false,
    },
  },

  {
    path: "/signup/profiling/declarations",
    name: "signupOtherDeclarations",
    component: OtherDeclarations,
    meta: {
      signup: true,
      signupFlow: "profiling",
    },
  },

  // Signup contract flow

  {
    path: "/account/compass-contract",
    name: "accountCompassContract",
    component: ContractView,
    meta: {
      requiresAuth: true,
      signup: true,
    },
  },

  {
    path: "/account/contract-signature-success",
    name: "accountContractSignatureSuccess",
    component: ContractSignatureSuccess,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/signup/contract",
    name: "signupContract",
    component: ContractView,
    meta: {
      signup: true,
      signupFlow: "contract",
    },
  },

  {
    path: "/signup/data-verification",
    name: "signupDataVerification",
    component: DataVerification,
    meta: {
      signup: true,
    },
  },

  {
    path: "/signup/error",
    name: "signupError",
    component: ErrorView,
    meta: {
      signup: true,
    },
  },

  {
    path: "/account/error",
    name: "accountError",
    component: ErrorView,
    meta: {
      requiresAuth: true,
    },
  },

  // investment flow

  {
    path: "/invest/view-funds",
    name: "investViewFunds",
    component: ViewFunds,
    meta: {
      requiresAuth: true,
      signup: true,
      showNavigation: true,
    },
  },

  {
    path: "/invest/fund-details/:fundRun",
    props: true,
    name: "investFundDetails",
    component: FundDetails,
    meta: {
      requiresAuth: true,
      signup: true,
    },
  },

  {
    path: "/invest/choose-investment-amount/:fundRun",
    props: true,
    name: "investChooseInvestmentAmount",
    component: ChooseInvestmentAmount,
    meta: {
      requiresAuth: true,
      signup: true,
    },
  },

  {
    path: "/invest/transactional-profile/intro",
    name: "investTransactionalProfileIntro",
    component: TransactionalProfileIntro,
    meta: {
      requiresAuth: true,
      signup: true,
    },
  },

  {
    path: "/invest/transactional-profile/questions",
    name: "investTransactionalProfileQuestions",
    component: TransactionalProfileQuestions,
    meta: {
      requiresAuth: true,
      signup: true,
    },
  },

  {
    path: "/account/compass-complete-data",
    name: "accountCompassCompleteData",
    component: AccountCompassCompleteData,
    meta: {
      requiresAuth: true,
      signup: true,
    },
  },

  {
    path: "/account/compass-complete-data-before-contract",
    name: "accountCompassCompleteDataBeforeContract",
    component: AccountCompassCompleteDataBeforeContract,
    meta: {
      requiresAuth: true,
      signup: true,
    },
  },

  {
    path: "/invest/compass-complete-data",
    name: "investCompassCompleteData",
    component: InvestCompassCompleteData,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/account/compass-signature-notice",
    name: "accountCompassSignatureNotice",
    component: CompassSignatureNotice,
    meta: {
      requiresAuth: true,
      signup: true,
    },
  },

  {
    path: "/invest/bank-select",
    name: "investBankSelect",
    component: BankSelect,
    meta: {
      requiresAuth: true,
      signup: true,
    },
  },

  {
    path: "/invest/select-transfer-option",
    name: "investSelectTransferOption",
    component: SelectTransferOption,
    meta: {
      requiresAuth: true,
      signup: true,
    },
  },

  {
    path: "/invest/bank-transfer",
    name: "investBankTransfer",
    component: BankTransfer,
    meta: {
      requiresAuth: true,
      signup: true,
    },
  },

  {
    path: "/invest/success",
    name: "investInvestmentSuccess",
    component: InvestmentSuccess,
    meta: {
      requiresAuth: true,
      signup: true,
    },
  },

  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      auth: true,
    },
  },

  {
    path: "/reset",
    name: "reset",
    component: Login,
    meta: {
      auth: true,
    },
  },

  {
    path: "/redemption/choose-flow/",
    name: "chooseRedemptionFlow",
    component: ChooseRedemptionFlow,
    meta: {
      requiresAuth: true,
      showNavigation: true,
    },
  },

  {
    path: "/redemption/reasons",
    name: "redemptionReasons",
    component: RedemptionReasons,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/redemption/origin",
    name: "redemptionOrigin",
    component: RedemptionOrigin,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/redemption/from-fund",
    name: "fromFundRedemption",
    component: FromFundRedemption,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/redemption/choose-amount",
    name: "chooseRedemptionAmount",
    component: ChooseRedemptionAmount,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/redemption/confirm-bank",
    name: "confirmBankAccount",
    component: ConfirmBankAccount,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/redemption/summary",
    name: "redemptionSummary",
    component: RedemptionSummary,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/redemption-reinvestment-success",
    name: "redemptionReinvestmentSuccess",
    component: RedemptionReinvestmentSuccess,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/reinvestment/select-origin-fund",
    name: "selectOriginFund",
    component: SelectOriginFund,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/reinvestment/select-destination-fund",
    name: "selectDestinationFund",
    component: SelectDestinationFund,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/reinvestment/choose-amount",
    name: "chooseReinvestmentAmount",
    component: ChooseReinvestmentAmount,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/reinvestment/summary",
    name: "reinvestmentSummary",
    component: ReinvestmentSummary,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/giftcard",
    name: "giftcards",
    // eslint-disable-next-line no-restricted-globals
    beforeEnter() {
      location.href = "https://giftcard.dvacapital.com/";
    },
  },

  {
    path: "/callback",
    name: "callback",
    component: Callback,
    props: true,
    meta: {
      callback: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const authenticatedStatus = await router.app.$auth.getAuthenticatedStatus();
  if (from.meta.callback) {
    next();
  }
  if (to.meta.auth && authenticatedStatus === "authenticated") {
    // If the user is not invested, App.vue redirects him/her to the signup dashboard
    next("/");
  }
  if (to.meta.requiresAuth) {
    if (authenticatedStatus === "not-authenticated") {
      store.dispatch("setQueryParams");
      next("/login");
    } else if (authenticatedStatus === "no-profile-data" && !to.meta.signup) {
      next("/signup/dashboard");
    } else if (authenticatedStatus === "authenticated" || to.meta.signup) {
      next();
    }
  } else if (to.meta.signup) {
    const { investedCustomer } = store.getters;
    if (authenticatedStatus === "authenticated" && investedCustomer) {
      next("/");
    }
    if (authenticatedStatus === "not-authenticated" && to.name !== "signupView") {
      store.dispatch("setQueryParams");
      next("/signup");
    } else if (authenticatedStatus === "not-authenticated" && to.name === "signupView") {
      next();
    } else {
      const { signupStatus } = store.getters;
      if ((to.meta.signupFlow === "identification" && signupStatus.completedIdentification) ||
        (to.meta.signupFlow === "profiling" && signupStatus.completedProfiling) ||
        (to.meta.signupFlow === "contract" && signupStatus.completedContract) ||
        to.name === "signupView") {
        next("/signup/dashboard");
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

// eslint-disable-next-line no-unused-vars
router.afterEach(async (to, from) => {
  // const { name, path, params, fullPath } = to;
  await router.app.$analytics.ready;
  const { name } = to;
  router.app.$analytics.page({
    name,
    ...(localStorage.getItem("userId") ? { userId: localStorage.getItem("userId") } : {}),
    anonymousId: localStorage.getItem("segmentAnonymousId"),
    // properties: to,
    // timestamp: dayjs(),
  });
});

export default router;
