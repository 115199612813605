<template>
  <div class="invest-wrapper">
    <div class="invest-content">
      <div class="invest-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>
      <div class="invest-amount-title-container">
        <span class="invest-amount-title">
          {{ $i18n.t("investment.bankSelect.label") }}
          {{ selectedFund | fundShortName }}
        </span>
        <v-icon
          dark
          size="10"
          class="mx-2 my-auto fund-icon"
          :style="`background-color: ${fundColor}`"
        >
          {{ fundIcon }}</v-icon
        >
      </div>

      <div class="invest-amount-subtitle">
        {{ $i18n.t("investment.bankTransfer.title") }}
      </div>

      <div class="banking-data-and-tips">
        <div class="banking-data">
          <FundBankingData :fund="Number(selectedFund)" />
          <CustomButton
            class="invest-btn mt-5"
            normalSpacing
            :text="$i18n.t('investment.bankTransfer.transferDone')"
            :outlined="false"
            :capitalize="false"
            color="#000000"
            :borderRadius="'10px'"
            :upperCase="false"
            :fontSize="'14px'"
            :width="'100%'"
            height="60px"
            disabledColor="#bdbdbd"
            v-if="$vuetify.breakpoint.mdAndUp"
            @on-click="toSuccess()"
          />
        </div>

        <div class="tiplist-container">
          <div v-for="(tip, index) in tips" :key="index" class="tip-list">
            <v-icon size="24" color="#333333">
              {{ tip.icon }}
            </v-icon>

            <div v-html="tip.text" class="tip-text" />
          </div>
        </div>
        <div class="pb-16">
          <CustomButton
            normalSpacing
            :text="$i18n.t('investment.bankTransfer.transferDone')"
            :outlined="false"
            color="#000000"
            :borderRadius="'10px'"
            :upperCase="false"
            :capitalize="false"
            :fontSize="'14px'"
            :width="'100%'"
            height="60px"
            disabledColor="#bdbdbd"
            v-if="$vuetify.breakpoint.smAndDown"
            @on-click="toSuccess()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import funds from "../../mixins/funds.js";
import clipboard from "../../mixins/clipboard.js";
import CustomButton from "../../components/shared/CustomButton.vue";
import FundBankingData from "../../components/investment/FundBankingData.vue";

export default {
  name: "BankTransfer",

  components: { CustomButton, FundBankingData },

  mixins: [funds, clipboard],

  computed: {
    ...mapGetters(["invest"]),

    selectedFund() {
      return this.invest.fundRun;
    },

    fundColor() {
      return this.$options.filters.addFundColor(this.selectedFund);
    },

    fundIcon() {
      return `mdi-${this.getFundStaticData(this.selectedFund).icon}`;
    },

    tips() {
      return [
        {
          text: this.$i18n.t("investment.bankTransfer.tips.anyAccount"),
          icon: "mdi-badge-account-horizontal-outline",
        },
        {
          text: this.$i18n.t("investment.bankTransfer.tips.anyAmount"),
          icon: "mdi-piggy-bank-outline",
        },
        {
          text: this.$i18n.t("investment.bankTransfer.tips.automatingTransfers"),
          icon: "mdi-creation",
        },
      ];
    },
  },

  methods: {
    toSuccess() {
      this.$router.push({ name: "investInvestmentSuccess" });
    },
  },
};
</script>

<style lang="scss" scoped>
.fund-icon {
  border-radius: 50%;
  height: 18px;
  width: 18px;
}

.invest-content {
  @media (max-width: 960px) {
    padding: 0 20px;
  }
}

.banking-data-and-tips {
  margin-top: 40px;
  display: flex;
  justify-content: left;
  flex-direction: row;
  gap: 2.5rem 5rem;

  @media (max-width: 960px) {
    margin-top: 20px;
    flex-direction: column;
    justify-content: center;
  }
}

.tip-list {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  gap: 1rem;
}
.tip-list + .tip-list {
  margin-top: 30px;
}

.tip-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #333333;
}
.tip-text b {
  font-weight: 600;
}

.tiplist-container {
  max-width: 400px;

  @media (max-width: 960px) {
    max-width: 100%;
  }
}
</style>
