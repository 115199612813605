<template>
  <div class="dividend-wrapper">
    <div class="dividend-content">
      <div class="dividend-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>
      <h3 class="dividend-subtitle">
        <div>
          {{ $t("dividendDeclaration.confirmDeclaration.title") }}
        </div>
        <div>
          <span id="option-text">
            {{ $t("dividendDeclaration.confirmDeclaration.option") }}
          </span>

          <span id="payment-reinvestment-text">
            {{
              $t(
                `dividendDeclaration.confirmDeclaration.${
                  dividendDeclarationAnswer === "reinvest"
                    ? "dividendReinvestment"
                    : "dividendPayment"
                }`,
              )
            }}
          </span>
        </div>
      </h3>
      <div class="dividend-text dividend-brief-container">
        <p>
          {{
            $t(
              `dividendDeclaration.confirmDeclaration.${
                dividendDeclarationAnswer === "reinvest" ? "reinvestText" : "depositText"
              }`,
            )
          }}
        </p>

        <div class="customer-declaration-data">
          <div>
            {{ declarationData.customerName }}
          </div>
          <div>
            {{ declarationData.customerIdentifier | formatRut }}
          </div>
          <div>
            {{ declarationData.currentDate }}
          </div>
        </div>
      </div>

      <NewButton
        :text="$t('base.confirm')"
        @on-click="confirmDeclaration()"
        :loading="loading"
      />

      <div class="dividend-cancel-container">
        <div class="dividend-cancel" @click="$router.push({ name: 'home' })">
          {{ $t("base.cancel") }}
        </div>
      </div>
    </div>

    <ReusableDialog
      :value="errorDialog"
      @display="errorDialog = $event"
      :title="$t('dividendDeclaration.confirmDeclaration.errors.title')"
      :text="$t(`dividendDeclaration.confirmDeclaration.errors.${errorType}`)"
      v-if="errorDialog"
    />
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import { operationsApiFunctions } from "@/mixins/operationsApiMixin.js";
import NewButton from "@/components/shared/NewButton.vue";
import ReusableDialog from "@/components/shared/ReusableDialog.vue";

export default {
  name: "ConfirmDividendDeclaration",

  data: () => ({
    loading: false,
    errorDialog: false,
    errorType: "",
  }),

  components: { NewButton, ReusableDialog },

  mixins: [operationsApiFunctions],

  computed: {
    ...mapGetters(["customerPendingDividends", "profile", "dividendDeclarationAnswer"]),

    declarationData() {
      return {
        customerName: `${this.profile?.name} ${this.profile?.lastName}`,
        customerIdentifier: this.profile?.identifier,
        currentDate: dayjs().format("DD-MM-YYYY"),
      };
    },
  },

  methods: {
    handleErrorResponse(response) {
      this.errorDialog = true;
      this.loading = false;

      const errorCode = response.find(response => response.errors)?.errors[0]?.extensions?.code;

      if (errorCode === "DIVIDEND_ALREADY_ANSWERED") {
        this.errorType = "alreadyAnswered";
      } else if (errorCode === "DIVIDEND_DECLARATION_DATE_EXPIRED") {
        this.errorType = "dateExpired";
      } else {
        this.errorType = "generic";
      }
    },

    async confirmDeclaration() {
      try {
        this.loading = true;

        // Create the body of each dividend declaration
        const declarations = this.customerPendingDividends.dividendList.map(
          dividend => ({
            customerDividendId: dividend.dividendId,
            dividendOptionValue: this.dividendDeclarationAnswer,
            answeredByName: `${this.profile?.name} ${this.profile?.lastName}`,
            answeredByIdentifier: this.profile?.identifier,
          }),
        );

        // Create the promises for each declaration
        const declarationPromisses = declarations.map(declaration => this.createDividendAnswer(declaration));

        // Perform the dividend declarations and wait for all of them to finish
        const dividendAnswerResponses = await Promise.all(declarationPromisses);

        const hasResponseErrors = dividendAnswerResponses.some(
          response => response?.errors,
        );
        this.loading = false;

        if (!hasResponseErrors) {
          this.$router.push({ name: "dividendsDeclarationSuccess" });
        } else {
          this.handleErrorResponse(dividendAnswerResponses);
        }
      } catch (e) {
        console.error(e);
        this.errorDialog = true;
        this.errorType = "generic";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dividend-brief-container {
  margin: 40px 0;
  padding-bottom: 40px;
  border-bottom: 1px solid #e2e0e0;
}

.dividend-wrapper {
  margin: 40px 20px;

  @media (max-width: 600px) {
    margin: 30px 20px;
  }
}
</style>
