<template>
  <div>
    <v-dialog
      v-model="display"
      :fullscreen="$vuetify.breakpoint.xs"
      max-width="500"
      content-class="rounded-lg elevation-0"
    >
      <v-card elevation="0" rounded="lg">
        <div class="modal-container edit-account-form">
          <div class="bank-account-form">
            <div class="bank-account-form-title">
              {{ $t(`shared.bankAccount.${!clpBankAccount ? "add" : "edit"}`) }}
            </div>
            <v-form v-model="valid" class="bank-account-form">
              <div class="edit-bank-label">
                {{ $t("shared.bankAccount.fields.RUT") }}
              </div>

              <TextField :value="rut" disabled />

              <div class="identifier-hint">
                {{ $t("shared.bankAccount.identifierHint") }}
              </div>

              <div class="edit-bank-label">
                {{ $t("shared.bankAccount.fields.currency") }}
              </div>

              <TextField
                disabled
                :placeholder="$t('shared.bankAccount.currencyPlaceholder')"
              />

              <div class="identifier-hint">
                {{ $t("shared.bankAccount.currencyHint") }}
              </div>

              <AutoComplete
                id="bank-name"
                :value="bank"
                :items="bankNames"
                :rules="bankRules"
                item-text="name"
                item-value="code"
                returnObject
                :placeholder="$t('shared.bankAccount.fields.bank')"
                @updateValue="bank = $event"
                class="edit-bank-field bank-dropdown"
              />

              <AutoComplete
                id="account-type"
                :value="accountType"
                :rules="bankRules"
                :placeholder="$t('shared.bankAccount.fields.bankAccountType')"
                :items="availableBankAccountTypes"
                item-text="type"
                item-value="code"
                returnObject
                @updateValue="accountType = $event"
                class="edit-bank-field bank-dropdown"
              />

              <div class="edit-bank-label">
                {{ $t("shared.bankAccount.fields.accountNumber") }}
              </div>

              <TextField
                :value="accountNumber"
                :rules="accountNumberRules"
                @updateValue="accountNumber = $event"
                id="accountNumber"
              />
            </v-form>

            <div class="button-wrapper">
              <NewButton
                :text="$t('shared.bankAccount.buttons.save')"
                buttonClass="black"
                :loading="loading"
                :disabled="saveDisabled"
                @on-click="createBankPatch()"
              />
              <div class="cancel-btn" @click="display = false">
                {{ $t("shared.bankAccount.buttons.cancel") }}
              </div>
            </div>
          </div>
        </div>
      </v-card>

    </v-dialog>
    <ReusableDialog
      v-model="errorDialog"
      :title="$t('shared.bankAccount.errorDialog.title')"
      :text="$t('shared.bankAccount.errorDialog.text')"
      persistent
      @display="errorDialog = $event"
      v-if="errorDialog"
      id="error-dialog"
    >
      <template v-slot:buttonRow>
        <div class="horizontal-button-row">
          <div class="reintent-button">
            <NewButton :text="$t('base.keepTrying')" @on-click="retryPatch()" />
          </div>

          <div class="cancel-button">
            <NewButton :text="$t('base.cancel')" buttonClass="black-outlined" @on-click="errorDialog = false" />
          </div>
        </div>

        <div class="help-action-text" @click="openChat()">
          {{ $t("base.help") }}
        </div>
      </template>
    </ReusableDialog>
  </div>
</template>

<script>
import { bankSelects } from "@/mixins/bankSelects.js";
import { mapGetters, mapActions } from "vuex";
import NewButton from "@/components/shared/NewButton.vue";
import AutoComplete from "@/components/shared/AutoComplete.vue";
import { customersApiFunctions } from "@/mixins/customersApiMixin.js";
import TextField from "./TextField.vue";
import ReusableDialog from "./ReusableDialog.vue";

export default {
  name: "EditBankAccountDialog",

  data() {
    return {
      valid: false,
      bank: {},
      accountType: {},
      accountNumber: null,
      rut: null,
      bankRules: [
        v => (v && Object.values(v).every(key => typeof key !== "undefined")) ||
          this.$t("shared.bankAccount.rules.bankRules"),
      ],
      accountNumberRules: [
        v => !!v || this.$t("shared.bankAccount.rules.mustComplete"),
      ],
      loading: false,
      errorDialog: false,
    };
  },

  components: {
    AutoComplete,
    NewButton,
    TextField,
    ReusableDialog,
  },

  props: {
    dialog: Boolean,
  },

  mixins: [bankSelects, customersApiFunctions],

  computed: {
    ...mapGetters(["profile", "clpBankAccount"]),

    display: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("display", value);
      },
    },

    saveDisabled() {
      if (!this.valid || this.emptyBankFields || this.unchangedForm) {
        return true;
      }
      return false;
    },

    emptyBankFields() {
      return (
        typeof this.bank?.id === "undefined" ||
        typeof this.accountType?.code === "undefined" ||
        !this.accountNumber
      );
    },

    unchangedForm() {
      return (
        this.patchBankPayload.accountNumber === this.clpBankAccount?.accountNumber &&
        this.patchBankPayload.bankId === this.clpBankAccount?.bankData?.id &&
        this.patchBankPayload.bankAccountTypeId === this.clpBankAccount?.accountTypeData?.id
      );
    },

    patchBankPayload() {
      return {
        id: this.profile.id,
        currency: "CLP",
        // bankInput
        bankId: this.bank?.id,
        accountNumber: this.accountNumber,
        bankAccountTypeId: this.accountType?.code,
      };
    },
  },

  methods: {
    ...mapActions(["setLoadingProfile", "setBankAccount"]),

    handleSuccessfulMutation() {
      this.updateBankData();
      this.showAuthenticationDialog = false;
      this.display = false;
      this.$emit("onPatchSuccess");
    },

    handleMutationErrors(error) {
      console.error(error);
      this.errorDialog = true;
    },

    async createBankPatch() {
      try {
        this.loading = true;
        const { errors } = await this.patchCustomerData({
          payload: this.patchBankPayload,
        });

        this.loading = false;

        if (!errors) {
          this.handleSuccessfulMutation();
        } else {
          this.handleMutationErrors(errors);
        }
      } catch (error) {
        this.handleMutationErrors(error);
      }
    },

    retryPatch() {
      this.errorDialog = false;
      this.createBankPatch();
    },

    openChat() {
      this.$intercom.showNewMessage(this.$t("shared.bankAccount.errorDialog.intercom"));
    },

    updateBankData() {
      const {
        accountNumber,
        currency,
        bankId,
        bankAccountTypeId,
      } = this.patchBankPayload;

      const newBankAccount = {
        accountNumber,
        currency,
        bank: {
          id: bankId,
          name: this.bankNames.find(bankItem => bankItem.id === bankId)?.name,
        },

        bankAccountType: {
          id: bankAccountTypeId,
          typeName: this.availableBankAccountTypes.find(
            accountTypeItem => accountTypeItem.id === bankAccountTypeId,
          )?.type,
        },
      };

      this.setBankAccount(newBankAccount);
    },

  },

  created() {
    this.bank = this.clpBankAccount?.bankData;
    this.accountType = this.clpBankAccount?.accountTypeData;
    this.accountNumber = this.clpBankAccount?.accountNumber;
    this.rut = this.profile.identifier;
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  height: 100%;
  padding: 40px;
}

.bank-account-form-title {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
}

.identifier-hint {
  font-weight: 500;
  font-size: 10px;
  line-height: 140%;
  color: #646363;
  margin: 10px 0 20px;
}

.edit-bank-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #333333;
  margin: 20px 0 10px;
}

.button-wrapper {
  margin-top: 20px;
}

.cancel-btn {
  text-align: center;
  margin-top: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #646363;

  &:hover {
    cursor: pointer;
  }
}

.bank-dropdown {
  cursor: pointer !important;
}

.horizontal-button-row > :first-child {
  margin-top: 40px;
}

.horizontal-button-row > :last-child {
  margin-top: 20px;
}

.help-action-text{
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.42px;
  text-align: right;
  color: #646363;
  margin-top: 40px;

  &:hover {
    cursor: pointer;
  }

}

</style>
