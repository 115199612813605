<template>
  <div class="invest-wrapper">
    <div class="invest-content">
      <div class="invest-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>
      <div class="invest-amount-title-container">
        <span class="invest-amount-title">
          {{ $i18n.t("investment.selectTransferOption.label") }}
          {{ selectedFund | fundShortName }}
        </span>
        <v-icon
          dark
          size="10"
          class="mx-2 my-auto fund-icon"
          :style="`background-color: ${fundColor}`"
        >
          {{ fundIcon }}</v-icon
        >
      </div>

      <div class="you-prefer">
        {{ $i18n.t("investment.selectTransferOption.title") }}
      </div>

      <ClickableOptionCard
        v-for="transferOption in transferOptions"
        :disabled="loadingFintoc"
        :id="transferOption.id"
        :key="transferOption.name"
        :title="
          $i18n.t(`investment.selectTransferOption.options.${transferOption.name}.title`)
        "
        :subtitle="
          $i18n.t(
            `investment.selectTransferOption.options.${transferOption.name}.subtitle`,
          )
        "
        :leftIcon="`mdi-${transferOption.icon}`"
        class="clickable-option-card"
        @optionClicked="selectTransferOption(transferOption.name)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import funds from "@/mixins/funds.js";
import { bankSelects } from "@/mixins/bankSelects.js";
import { operationsApiFunctions } from "@/mixins/operationsApiMixin.js";
import { fintocMixin } from "@/mixins/fintoc.js";
import ClickableOptionCard from "@/components/shared/ClickableOptionCard.vue";

export default {
  name: "SelectTransferOption",

  mixins: [funds, bankSelects, operationsApiFunctions, fintocMixin],

  components: { ClickableOptionCard },

  data: () => ({
    loadingFintoc: false,
    transferOptions: [
      { id: "use-fintoc", name: "USE_FINTOC", icon: "arrow-right-bold-outline" },
      { id: "transfer-here", name: "TRANSFER_HERE", icon: "bank-outline" },
    ],
  }),

  computed: {
    ...mapGetters([
      "loading",
      "profile",
      "invest",
      "funds",
      "fintocStatus",
      "signupEmail",
      "signupRut",
    ]),

    selectedFund() {
      return this.invest.fundRun;
    },

    fundColor() {
      return this.$options.filters.addFundColor(this.selectedFund);
    },

    fundIcon() {
      return `mdi-${this.getFundStaticData(this.selectedFund).icon}`;
    },
  },

  methods: {
    ...mapActions(["setFintocStatus"]),

    async onFintocExit(fintocExitResponse) {
      const paymentIntentFintocId = fintocExitResponse.data.data.createPaymentIntent.id;
      await this.patchPaymentIntent({
        fintocId: paymentIntentFintocId,
        status: "CANCELED",
      });
      if (this.fintocStatus !== null) await this.setFintocStatus(null);
      this.closeFintoc();
    },

    async onFintocError(fintocErrorResponse) {
      const paymentIntentFintocId = fintocErrorResponse.data.data.createPaymentIntent.id;
      await this.patchPaymentIntent({ fintocId: paymentIntentFintocId, status: "ERROR" });
      if (this.fintocStatus !== null) await this.setFintocStatus(null);
      this.closeFintoc();
    },

    async onFintocSuccess(fintocSuccessResponse) {
      const paymentIntentFintocId =
        fintocSuccessResponse.data.data.createPaymentIntent.id;
      await this.patchPaymentIntent({ fintocId: paymentIntentFintocId, status: "PAID" });
      if (this.fintocStatus !== "SUCCESS") await this.setFintocStatus("SUCCESS");
      this.closeFintoc();
      this.$router.push({ name: "investInvestmentSuccess" });
    },

    selectTransferOption(optionName) {
      if (optionName === "USE_FINTOC") {
        this.loadingFintoc = true;
        const customerIdentifier = this.profile?.identifier || this.signupRut;

        if (this.fintocStatus !== "IN_PROCESS") this.setFintocStatus("IN_PROCESS");

        this.createPaymentIntent({
          fundRun: this.selectedFund,
          amount: this.invest.amount,
          ...(customerIdentifier ? { customerIdentifier } : {}),
        })
          .then(response => {
            if (window.Cypress) {
              this.$router.push({ name: "investInvestmentSuccess" });
            }
            if (response.errors) {
              this.$router.go(-1);
            }
            const { widgetToken } = response.data.data.createPaymentIntent;

            const widgetArgs = {
              customerIdentifier,
              institutionId: this.bankNames.find(bank => bank.id === this.invest.bankId)
                .fintocId,
              widgetToken,
            };

            this.openFintoc({
              ...widgetArgs,
              onSuccess: () => this.onFintocSuccess(response),
              onExit: () => this.onFintocExit(response),
              onEvent: event => {
                if (event === "on_error") {
                  this.onFintocError(response);
                }
              },
            });

            this.loadingFintoc = false;
          })
          .catch(err => {
            this.$router.go(-1);
            return err;
          });
      } else {
        if (this.fintocStatus !== null) this.setFintocStatus(null);
        this.$router.push({ name: "investBankTransfer" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.invest-content {
  @media (max-width: 600px) {
    margin: 0 20px;
  }
}
.clickable-option-card {
  max-width: 420px;
}

.clickable-option-card + .clickable-option-card {
  margin-top: 10px;
}

.you-prefer {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  color: #333333;
  margin-bottom: 40px;
  @media (max-width: 600px) {
    margin-bottom: 20px;
  }
}

.fund-icon {
  border-radius: 50%;
  height: 18px;
  width: 18px;
}
</style>
