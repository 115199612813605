import { render, staticRenderFns } from "./OtherDeclarations.vue?vue&type=template&id=468e559a&scoped=true"
import script from "./OtherDeclarations.vue?vue&type=script&lang=js"
export * from "./OtherDeclarations.vue?vue&type=script&lang=js"
import style0 from "./OtherDeclarations.vue?vue&type=style&index=0&id=468e559a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "468e559a",
  null
  
)

export default component.exports