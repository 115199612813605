<template>
  <div class="step-content px-0 pt-0">
    <div class="pa-0">
      <AnimationWrapper
        :images="successImages"
        :lazyImage="lazyImage"
        class="loading-images"
      />
      <div class="success-content">
        <div class="success-texts">
          <div class="mx-6 title-row mb-2">
            <span class="success-title">
              {{ $i18n.t("investment.success.title") }}
            </span>
          </div>
          <div class="success-subtitle mx-6 my-2">
            {{ successMessage }}
          </div>
        </div>
        <div class="button-row center-in-page flex-column mt-md-8">
          <div class="success-btn-wrapper">
            <NewButton
              :text="$i18n.t('investment.success.buttonText')"
              @on-click="
                $router.push({ name: investedCustomer ? 'home' : 'signupDashboard' })
              "
              id="finish-investment"
            />
          </div>
          <div
            class="support-text mt-5"
            @click="$intercom.showNewMessage('Hola! Tengo dudas con mi aporte')"
          >
            {{ $i18n.t("investment.success.support") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import lazyLoadingImage from "../../assets/animations/success/success-lazy-img.png";
import success1 from "../../assets/animations/success/success1.svg";
import success2 from "../../assets/animations/success/success2.svg";
import success3 from "../../assets/animations/success/success3.svg";
import NewButton from "../../components/shared/NewButton.vue";
import AnimationWrapper from "../../components/shared/AnimationWrapper.vue";

export default {
  name: "InvestmentSuccess",

  data: () => ({
    successImages: [success1, success2, success3],
    lazyImage: lazyLoadingImage,
  }),

  components: { NewButton, AnimationWrapper },

  computed: {
    ...mapGetters(["investedCustomer", "fintocStatus"]),

    successMessage() {
      if (this.fintocStatus === "SUCCESS") {
        return this.$i18n.t("investment.success.fintocSuccess");
      }
      return this.$i18n.t("investment.success.manualTransferSuccess");
    },
  },

  methods: {
    ...mapActions(["setInvestTime"]),
  },

  mounted() {
    const currentDate = this.$dayjs();
    this.setInvestTime(currentDate);
  },
};
</script>

<style lang="scss" scoped>
.success-title {
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 140%;
  color: #000000;
}

.success-btn-wrapper {
  width: 100%;
  max-width: 420px;
  @media (max-width: 960px) {
    max-width: 100%;
    padding: 0 20px;
  }
}

::v-deep .black-btn {
  span {
    font-size: 14px !important;
    line-height: 16px !important;
  }
}

.loading-images {
  margin-top: 60px;
  @media (max-width: 600px) {
    margin-top: 50px;
  }
}

::v-deep .animation-wrapper {
  height: 340px;
}

.success-subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  color: #333333;
  max-width: 420px;
}

.support-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #646363;

  &:hover {
    cursor: pointer;
  }
}

.success-content {
  @media (min-width: 960px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

.title-row {
  margin-top: 20px !important;
}

.success-texts {
  @media (max-width: 960px) and (min-width: 600px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.button-row {
  width: 100%;
  margin-top: 40px;
}
</style>
