import { customersApiFunctions } from "./customersApiMixin.js";

export const certificatesFunctions = {

  data() {
    return {
      monthList: [
        { value: "01", name: "Enero" },
        { value: "02", name: "Febrero" },
        { value: "03", name: "Marzo" },
        { value: "04", name: "Abril" },
        { value: "05", name: "Mayo" },
        { value: "06", name: "Junio" },
        { value: "07", name: "Julio" },
        { value: "08", name: "Agosto" },
        { value: "09", name: "Septiembre" },
        { value: "10", name: "Octubre" },
        { value: "11", name: "Noviembre" },
        { value: "12", name: "Diciembre" },
      ],
    };
  },

  mixins: [customersApiFunctions],

  computed: {
    yearList() {
      const currentYear = new Date().getFullYear();
      // Returning the last five years
      return Array.from({ length: 5 }, (_, index) => currentYear - index);
    },
  },

  methods: {
    async generateInvestmentCertificate(reportDate) {
      try {
        // Due to a timeout error, the next api call will be commented to the
        // Graph and the call will be made directly to the account statements service.

        //  const { data: certificateFile, error } = await this.createAccountStatement(reportDate);

        const payload = {
          account_id: this.$auth.getProfile().id,
          date: reportDate,
        };

        const token = this.$auth.accessToken;

        const headers = {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        };

        const { data } = await this.axios.post(process.env.VUE_APP_ACCOUNT_STATEMENTS, payload, { headers });

        const certificateFile = data?.file;

        if (!certificateFile) return { data: null, error: true };

        this.downloadCertificate(certificateFile, reportDate);

        return { data: certificateFile, error: false };
      } catch (error) {
        console.error(error);
        return { data: null, error: true };
      }
    },

    downloadCertificate(pdf, reportDate) {
      const base64Link = `data:application/pdf;base64,${pdf}`;

      const downloadLink = document.createElement("a");
      downloadLink.href = base64Link;

      const fileName = this.getFileName(reportDate);
      downloadLink.download = fileName;
      downloadLink.click();
    },

    getFileName(reportDate) {
      const { name, lastName } = this.$store.getters.profile;
      const firstName = name.split(" ")[0];
      const normalizedFirstName = this.normalizeString(firstName);

      let customerName = normalizedFirstName;

      if (lastName) {
        const firstLastName = lastName.split(" ")[0];
        const normalizedLastName = this.normalizeString(firstLastName);
        customerName = `${normalizedFirstName}_${normalizedLastName}`;
      }

      return `${customerName}_${reportDate}`;
    },

    normalizeString(str) {
      // removing accents and applying lower case function
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    },

  },

};
